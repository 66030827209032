import React, { useState, useRef } from "react";
import axios from "axios";
import WavEncoder from "wav-encoder";

import "./AudioTest.scss"

const AudioTest = () => {
  const [recording, setRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [error, setError] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const audioChunks = useRef([]);

  const handleStartRecording = () => {
    setError(null);
    setTranscription("");

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);
          audioChunks.current = [];

          recorder.ondataavailable = (event) => {
            audioChunks.current.push(event.data);
          };

          recorder.onstop = async () => {
            const audioBlob = new Blob(audioChunks.current, {
              type: "audio/wav",
            });
            await processAndSendAudio(audioBlob); // Process and send the audio file directly
          };

          recorder.start();
          setRecording(true);
        })
        .catch((err) =>
          setError("Microphone access is required to record audio.")
        );
    } else {
      setError("Your browser does not support audio recording.");
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const processAndSendAudio = async (audioBlob) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(audioBlob);

    reader.onloadend = async () => {
      const arrayBuffer = reader.result;

      // Set up AudioContext with the required sample rate (16,000 Hz)
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)({ sampleRate: 16000 });
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      // Ensure mono audio
      const channelData = [audioBuffer.getChannelData(0)];

      // Encode the audio as a high-quality WAV file (16-bit depth, mono, 16,000 Hz)
      const wavData = await WavEncoder.encode({
        sampleRate: 16000, // 16,000 Hz sample rate
        channelData: channelData, // Mono audio (1 channel)
        bitDepth: 16, // 16-bit depth
      });

      // Create a high-quality WAV Blob and URL to play the audio (optional)
      const wavBlob = new Blob([wavData], { type: "audio/wav" });
      const audioUrl = URL.createObjectURL(wavBlob);
      setAudioUrl(audioUrl);

      // Send the WAV file directly to the backend
      await sendAudioToBackend(wavBlob);
    };
  };

  const sendAudioToBackend = async (wavBlob) => {
    const formData = new FormData();
    formData.append("file", wavBlob, "audio.wav");

    try {
      // Send the file to the backend
      const response = await axios.post("/api/transcribe-audio", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.error) {
        setError(response.data.error);
      } else {
        setTranscription(response.data.transcription);
      }
    } catch (err) {
      setError("An error occurred during transcription.");
    }
  };

  return (
    <div className="audio-test">
      <h1>Audio Test</h1>
      <div className="recorder">
        {recording ? (
          <button onClick={handleStopRecording}>Stop Recording</button>
        ) : (
          <button onClick={handleStartRecording}>Start Recording</button>
        )}
      </div>

      {audioUrl && (
        <audio controls>
          <source src={audioUrl} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
      )}

      {error && <p className="error">{error}</p>}
      {transcription && <p className="transcription">{transcription}</p>}
    </div>
  );
};

export default AudioTest;
