import React from "react";

import "./Profile.scss";

const Profile = () => {
  return (
    <div className="profile">
      <h1 className="under-construction">Under Construction</h1>
      <h1>Profile</h1>
      <p>Nothing here yet.</p>
    </div>
  );
};

export default Profile;
