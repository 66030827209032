import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

import { logout } from "../../Utils/Auth/auth";

import "./Footer.scss";

const Footer = ({ isLoggedIn }) => {
  const navigate = useNavigate();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section links">
          <h2>Quick Links</h2>
          <ul>
            <li>
              <span onClick={() => navigate("/")}>Home</span>
            </li>
            <li>
              <span onClick={() => navigate("/about")}>About</span>
            </li>
            <li>
              <span onClick={() => navigate("/contact")}>Contact</span>
            </li>
            {isLoggedIn ? (
              <>
                <li>
                  <span onClick={() => navigate("/dashboard")}>Dashboard</span>
                </li>
                <li>
                  <span onClick={() => navigate("/profile")}>Profile</span>
                </li>
                <li>
                  <button onClick={logout}>Logout</button>
                </li>
              </>
            ) : (
              <>
                <li>
                  <span onClick={() => navigate("/login")}>Login</span>
                </li>
                <li>
                  <span onClick={() => navigate("/signup")}>Sign Up</span>
                </li>
              </>
            )}
            <li>
              <span onClick={() => navigate("/policy")}>Policy</span>
            </li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h2>Contact Us</h2>
          <span>
            <FontAwesomeIcon icon={faEnvelope} />
            <p>earlyreaderai@gmail.com</p>
          </span>
          <span>
            <FontAwesomeIcon icon={faPhone} />
            <p>+1 (385) 501-7370</p>
          </span>
        </div>
        <div className="footer-section social">
          <h2>Follow Us</h2>
          <div className="social-icons">
            <a href="https://github.com/MCVNick" aria-label="Github">
              <FontAwesomeIcon icon={faGithub} />
            </a>
            <a
              href="https://www.linkedin.com/in/n-mcqueen/"
              aria-label="LinkedIn"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2025 EarlyReaderAI. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
