import React from "react";
import { Link } from "react-router-dom";

import "./Home.scss";

const Home = () => {
  return (
    <div className="home">
      <header className="home-header">
        <h1>Welcome to EarlyReaderAI</h1>
        <p>Empowering students to read using cutting-edge AI technology.</p>
      </header>
      <main className="home-main">
        <section className="features">
          <Link to="/audio-test" className="feature-card">
            <h2>Audio Test</h2>
            <p>Analyze your pronunciation and improve your speaking skills.</p>
          </Link>
          <Link to="/phoneme-analysis" className="feature-card">
            <h2>Phoneme Analysis</h2>
            <p>
              Break down words into phonemes and understand their structure.
            </p>
          </Link>
          <Link to="/lesson-select" className="feature-card">
            <h2>Lesson Select</h2>
            <p>
              Choose interactive lessons designed to enhance reading skills.
            </p>
          </Link>
        </section>
      </main>
    </div>
  );
};

export default Home;
