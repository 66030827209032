import React, { useState } from "react";

import {
  convertCmuToIpa,
  getPhonemes,
  mapIpaToGraphemes,
} from "../../Utils/Phones/phones";

import "./PhonemeAnalysis.scss";

const PhonemeAnalysis = () => {
  const [phonesInput, setPhonesInput] = useState("");
  const [graphemeTiles, setGraphemeTiles] = useState([]);
  const [error, setError] = useState("");

  const handleGetGraphemes = async () => {
    setError("");
    setGraphemeTiles([]);

    if (!phonesInput) {
      setError("Input cannot be blank");
      return;
    }

    try {
      const cmuPhonemes = await getPhonemes(phonesInput);
      if (!cmuPhonemes || typeof cmuPhonemes === "string") {
        setError(cmuPhonemes || "Invalid word");
        return;
      }

      const ipaPhonemes = convertCmuToIpa(cmuPhonemes.flat(), phonesInput);
      const tiles = mapIpaToGraphemes(ipaPhonemes, phonesInput);
      setGraphemeTiles(tiles);
    } catch (error) {
      setError("An error occurred while processing the input.");
      console.error(error);
    }
  };

  return (
    <div className="phoneme-analysis">
      <h1>Phoneme Analysis</h1>
      <input
        type="text"
        placeholder="Enter a word..."
        onChange={(e) => setPhonesInput(e.target.value)}
        onKeyDown={(e) => {
          e.key === "Enter" && handleGetGraphemes();
        }}
      />
      <button onClick={handleGetGraphemes}>Get Graphemes</button>

      {error && <p className="error">{error}</p>}

      {graphemeTiles.length > 0 && (
        <div className="phon-analysis-grapheme">
          {graphemeTiles.map((tile, index) => {
            return (
              <div key={`grapheme-tiles-${tile}-${index}`} className="phon-tile">
                <p>{tile}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PhonemeAnalysis;
