import React from "react";

import "./Dashboard.scss";

const Dashboard = () => {
  return (
    <div className="dashboard">
      <h1 className="under-construction">Under Construction</h1>
      <h1>Dashboard</h1>
      <p>Nothing here yet.</p>
    </div>
  );
};

export default Dashboard;
